import React, { useState } from "react";
import "../Styles/Workbook.css";
import BackIcon from "../Assets/Images/back-icon.svg";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useGetBusinessmaterialviewQuery, useDeleteBusinessmaterialMutation } from "../Services/SecondaryApi";
import { useNavigate } from "react-router-dom";
import UploadModal from "../Components/UploadBusinessMaterial";
import Swal from 'sweetalert2';

export default function Workbook() {
  const { businessmaterial_id, subcategory_id, materialtype_id } = useParams();
  const [showUploadModal, setShowUploadModal] = useState(false);
  
  // Fetching the business material view data
  const { data, isLoading, error,refetch } = useGetBusinessmaterialviewQuery({ businessmaterial_id, subcategory_id, materialtype_id });
  
  // Delete mutation hook
  const [deleteBusinessmaterial] = useDeleteBusinessmaterialMutation();

  // Function to handle delete action
  const handleDelete = async (materialId) => {
    try {
      await deleteBusinessmaterial({ businessmaterial_id: materialId }).unwrap();
      // alert('Material deleted successfully');
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful!',
        text: 'Material deleted successfully!',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      // You may want to refetch the data here after deleting
    } catch (err) {
      // console.error('Failed to delete material:', err);
      // alert('Failed to delete the material.');
      Swal.fire({
        icon: 'error',
        title: 'Failed to delete',
        text: 'An error occurred. Please try again.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };
  const navigate = useNavigate();
  const handleBackClick = () => {
      navigate(-1); // Go back to the previous page
    };
const handleShowUploadMOdall = ()=>{
  setShowUploadModal(true);
}
const handleCloaseUploadModal =()=>{
  setShowUploadModal(false);
}
  return (
    <div>
      <Link onClick={handleBackClick}>
        <img src={BackIcon} alt="BackIcon" className="back-icon" />
      </Link>
      <div className="table-wrapper-div">
        <div className="sub-haed-table">       
          <div className="d-flex justify-content-space-between">
          <h4>{data?.data[0]?.material_type_name}</h4>
                    <button className="primary-btn"  onClick={handleShowUploadMOdall}>Upload</button>
                    </div>
        </div>

        
        {isLoading && <p>Loading ...</p>}
        {error && <p>No records found for the specified Business Material, Subcategory, and Material Type</p>}
        {!isLoading && !error && data && (
          <div className="content-div">
            <Row>
              {data?.data?.length > 0 && data.data.map((materialview) => (
                <Col md={3} sm={12} lg={3} key={materialview.material_id}>
                  <div className="card">
                    <div className="card-img">
                      <img src={materialview.thumbnail_path } />
                    </div>
                    <div className="card-body">
                      <p>{materialview.title}</p>
                      <div className="card-action-btn">
                        {/* Delete button with handleDelete */}
                        <button 
                          className="delete-btn"
                          onClick={() => handleDelete(materialview.id)}
                        >
                          Delete
                        </button>
                        <a href={materialview.file_path} target="_blank" rel="noopener noreferrer" className="w-100">
                          <button className="view-btn">View</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {data?.data?.length === 0 && <p>No materials available.</p>}
            </Row>
          </div>
        )}
      </div>
      <UploadModal businessmaterial_id={businessmaterial_id} subcategory_id={subcategory_id} materialtype_id={materialtype_id} refetch={refetch} show={showUploadModal} handleCloaseUploadModal={handleCloaseUploadModal}/>
    </div>
  );
}
