import React from "react";
import { Link } from "react-router-dom";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetAllmaterialsQuery, useAddBusinessmaterialsMutation } from "../Services/SecondaryApi";
import AddBusinessMaterialModal from "../Components/AddBusinessMaterial"
import AddCategoryModal from "../Components/AddBusinessCategory"
import { useState } from "react";


function MediaGallery() {
  const { data, isLoading, error,refetch } = useGetAllmaterialsQuery();
const [showAddBusinessMaterialModal, setShowBusinessMaterialModal] = useState(false);
const [showAddBusinessCategoryModal, setshowAddBusinessCategoryModal] = useState(false);

const handleShowBusinessMaterialModal = ()=>{
  setShowBusinessMaterialModal(true);
}
const handleCloseAddBusinessMaterialModal=()=>{
  setShowBusinessMaterialModal(false)
}

const handleShowBusinessCategoryModal = ()=>{
  setshowAddBusinessCategoryModal(true);
}
const handleCloseAddBusinessCategoryModal=()=>{
  setshowAddBusinessCategoryModal(false)
}

  return (
    <div>
      <h3>Business development materials</h3>
      <div className="table-wrapper-div">
        <div className="sub-haed-table">
          <div className="d-flex justify-content-space-between">
            <div>
              <h4>Materials</h4>
            </div>
            <div className="add-div">
              <button className="primary-btn" onClick={handleShowBusinessMaterialModal}>
                Add Business Material
              </button>
              <button className="primary-btn" onClick={handleShowBusinessCategoryModal}>
              Add Category
              </button>
            </div>

          </div>

        </div>
        {isLoading && <p>Loading materials...</p>}
        {error && <p>Failed to load materials. Please try again later.</p>}
        {!isLoading && !error && data && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Category</th>
                <th scope="col">No of subcategories</th>
                <th scope="col">No of documents</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((material) => (
                <tr key={material.id}>
                  <td className="clr-bold">{material.business_materials}</td>
                  <td>{material.subcategories_count}</td>
                  <td>{material.No_of_Documents}</td>
                  <td>
                    <Link to={`/business-developement-subcategory/${material.id}`}>
                      {/* <Link to="/cafe-startup/"> */}

                      <button className="btn-outline">View</button>
                    </Link>
                    {/* <button className="btn-outline ml-15">Approve</button>{" "} */}
                    {/* <button className="btn-outline ml-15">Upload</button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <AddBusinessMaterialModal show={showAddBusinessMaterialModal} handleCloseAddBusinessMaterialModal={handleCloseAddBusinessMaterialModal} refetch={refetch}/>
      <AddCategoryModal show={showAddBusinessCategoryModal} handleCloseAddBusinessCategoryModal={handleCloseAddBusinessCategoryModal} refetch={refetch}/>
    </div>
  );
}

export default MediaGallery;


