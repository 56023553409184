import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../Styles/Dashboard.css";
import Logo from "../Assets/Images/logo.svg";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DirectoryListingImg from "../Assets/Images/directory-listing-icon.svg";
import BusinessMentorship from "../Assets/Images/business-mentorship.svg";
import Community from "../Assets/Images/community.svg";
import EcosystemImg from "../Assets/Images/ecosystem-menu-icon.svg";
import Settings from "../Assets/Images/settings.svg";
import Support from "../Assets/Images/support-img.svg";
import UsernmanagementImg from "../Assets/Images/user-management.svg";
import RoleManagementImg from "../Assets/Images/role-management.svg";
import LIstingManagementImg from "../Assets/Images/list-management.svg";
import MembershipImg from "../Assets/Images/memebership.svg";
import BusinessDevelopmentMaterials from "../Assets/Images/business-development-material.svg";
import MediaGallery from "../Assets/Images/media-gallery.svg";
import ArtcleIMg from "../Assets/Images/articles.svg";
import ContactInfoImg from "../Assets/Images/contact-info.svg";
import EcosysystemNotificationImg from "../Assets/Images/ecosystem-notification-menu.svg"
import SupportImg from "../Assets/Images/support-menu-img.svg"
import {
  useRefreshtokenMutation
} from "../Services/AdminApi"
import { Link } from "react-router-dom";

function SidebarComponent({ handleChange }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useRefreshtokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // const handleSubmitRefreshToken = async () => {
  //   const  Token =localStorage.getItem("AdminrefreshToken")
  //     const postData = { Token };
  //     try {
  //       const response = await submitRefreshToken(postData).unwrap();
  //       if (response) {
  //         console.log("response", response);
  //         localStorage.setItem("AdminAccessToken", response.access);
  //         localStorage.setItem("AdminrefreshToken", response.refresh);
  //       }
  //     } catch (err) {
  //       console.error("Failed to refresh token:", err);
  //     }
  //   };
  const handleSubmitRefreshToken = async () => {
    let postData = {
      refresh :localStorage.getItem("AdminrefreshToken")
    } 
    try {
      const response = await submitRefreshToken(postData).unwrap();
  
      if (response) {
        console.log("response", response);
        localStorage.setItem("AdminAccessToken", response.access_token);
        localStorage.setItem("AdminrefreshToken", response.refresh_token);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };



  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 15000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  const [role, setRole] = useState("")

  const roletype = localStorage.getItem("Adminrole");
  useEffect(() => {
    if (roletype) {
      setRole(roletype)
    }
  }, [roletype])

  const handleSubMenuClick = (menuName) => {
    setActiveSubMenu((prev) => (prev === menuName ? null : menuName));
  };


  return (
    <>
      <button className="toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>
      <Sidebar className={isSidebarOpen ? "Sidebar active" : "Sidebar"}>
        <button className="close-btn" onClick={closeSidebar}>
          ×
        </button>
        <img src={Logo} className="img" alt="Logo" />
        <Menu>
          <SubMenu
            label="User Management"
            icon={
              <img
                src={UsernmanagementImg}
                alt="Community"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "UserManagement"}
            onClick={() => handleSubMenuClick("UserManagement")}
          >
            <Link to="/user-cafe">
              <MenuItem
                className={
                  window.location.pathname === "/user-cafe"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Cafe Entrepreneur
              </MenuItem>
            </Link>
            <Link to="/user-food">
              <MenuItem
                className={
                  window.location.pathname === "/user-food"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Foodservice
              </MenuItem>
            </Link>
            <Link to="/user-trade">
              <MenuItem
                className={
                  window.location.pathname === "/user-trade"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Trades & Services
              </MenuItem>
            </Link>
          </SubMenu>


          {role === "superadmin" && <Link
            to="/role-management"
            className={
              window.location.pathname === "/role-management"
                ? "sub-menu-item-active"
                : ""
            }
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={RoleManagementImg}
                alt="RoleManagementImg"
                className="menu-icon"
              />
              Role Management
            </MenuItem>
          </Link>}
          {/* 
          <Link
            to="/list-management"
            className={
              window.location.pathname === "/listing-management"
                ? "sub-menu-item-active"
                : ""
            }
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={LIstingManagementImg}
                alt="LIstingManagementImg"
                className="menu-icon"
              />
                Listing Management
            </MenuItem>
          </Link> */}

          <SubMenu
            label="List Management"
            icon={
              <img
                src={LIstingManagementImg}
                alt="Community"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "ListManagement"}
            onClick={() => handleSubMenuClick("ListManagement")}
          >
            <Link to="/list-management-cafe">
              <MenuItem
                className={
                  window.location.pathname === "/list-management-cafe"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Cafe Entrepreneur
              </MenuItem>
            </Link>
            <Link to="/list-management-food">
              <MenuItem
                className={
                  window.location.pathname === "/list-management-food"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Foodservice
              </MenuItem>
            </Link>
            <Link to="/list-management-trade">
              <MenuItem
                className={
                  window.location.pathname === "/list-management-trade"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Trades & Services
              </MenuItem>
            </Link>
          </SubMenu>
          { }
          <Link
            to="/membership"
            className={
              window.location.pathname === "/membership"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "membership"}
            onClick={() => handleSubMenuClick("membership")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={MembershipImg}
                alt="MembershipImg"
                className="menu-icon"
              />
              Membership
            </MenuItem>
          </Link>

          <SubMenu
            label="Business Mentorship"
            icon={
              <img
                src={BusinessMentorship}
                alt="BusinessMentorship"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "BusinessMentorship"}
            onClick={() => handleSubMenuClick("BusinessMentorship")}
          >
            <Link to="/business-mentorship">
              <MenuItem
                className={
                  window.location.pathname === "/business-mentorship"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Huddle Sessions
              </MenuItem>
            </Link>
          </SubMenu>
          <Link
            to="/business-developement-matrials"
            className={
              window.location.pathname === "/business-developement-matrials"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "businessdevelepmentmaterial"}
            onClick={() => handleSubMenuClick("businessdevelepmentmaterial")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={BusinessDevelopmentMaterials}
                alt="BusinessDevelopmentMaterials"
                className="menu-icon"
              />
              Business Development Materials
            </MenuItem>
          </Link>
       
          <SubMenu
            label="Media Gallery"
            icon={
              <img
                src={MediaGallery}
                alt="MediaGallery"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "MediaGallery"}
            onClick={() => handleSubMenuClick("MediaGallery")}
          >

            <Link to="/mediagallery-foodservice">
              <MenuItem
                className={
                  window.location.pathname === "/mediagallery-foodservice"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Foodservice
              </MenuItem>
            </Link>
            <Link to="/mediagallery-tradeservice">
              <MenuItem
                className={
                  window.location.pathname === "/mediagallery-tradeservice"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Trades & Services
              </MenuItem>
            </Link>
          </SubMenu>

          <SubMenu
            label="Community"
            icon={<img src={Community} alt="Community" className="menu-icon" />}
            open={activeSubMenu === "Community"}
            onClick={() => handleSubMenuClick("Community")}
          >
            <Link to="/notice-board">
              <MenuItem
                className={
                  window.location.pathname === "/notice-board"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Notice Board
              </MenuItem>
            </Link>
            <Link to="/community-chat">
              <MenuItem
                className={
                  window.location.pathname === "/community-chat"
                    ? "sub-menu-item-active"
                    : ""
                }
              >
                Chat
              </MenuItem>
            </Link>
          </SubMenu>
     
          <Link
            to="/articles"
            className={
              window.location.pathname === "/articles"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "articles"}
            onClick={() => handleSubMenuClick("articles")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img src={ArtcleIMg} alt="ArtcleIMg" className="menu-icon" />
              Articles
            </MenuItem>
          </Link>
          <Link
            to="/contact-info"
            className={
              window.location.pathname === "/contact-info"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "contact"}
            onClick={() => handleSubMenuClick("contact")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={ContactInfoImg}
                alt="ContactInfoImg"
                className="menu-icon"
              />
              Contact inquiries
            </MenuItem>
          </Link>
          <Link
            to="/ecosystem-statistics"
            className={
              window.location.pathname === "/ecosystem-statistics"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "ecosyastemstatics"}
            onClick={() => handleSubMenuClick("ecosyastemstatics")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={EcosystemImg}
                alt="EcosystemImg"
                className="menu-icon"
              />
              Ecosystem Statistics
            </MenuItem>
          </Link>
          <Link
            to="/ecosystem-notification"
            className={
              window.location.pathname === "/ecosystem-notification"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "ecosyastemNotification"}
            onClick={() => handleSubMenuClick("ecosyastemNotification")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={EcosysystemNotificationImg}
                alt="EcosystemImg"
                className="menu-icon"
              />
              Ecosystem Notification
            </MenuItem>
          </Link>
          <Link
            to="/support-management"
            className={
              window.location.pathname === "/support-management"
                ? "sub-menu-item-active"
                : ""
            }
            open={activeSubMenu === "support"}
            onClick={() => handleSubMenuClick("support")}
          >
            {" "}
            <MenuItem className="pl-5">
              <img
                src={SupportImg}
                alt="EcosystemImg"
                className="menu-icon"
              />
              Support
            </MenuItem>
          </Link>
        </Menu>
      </Sidebar>
    </>
  );
}

export default SidebarComponent;
