import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import BackIcon from "../Assets/Images/back-icon.svg";
import { useGetBusinessmaterialtypeQuery } from "../Services/SecondaryApi";
import { useNavigate } from "react-router-dom";
import AddMaterialtypeMpdal from "../Components/AddBusinessMaterialType"

function BusinessDevelopmentMaterialtype() {
    const { id, mid } = useParams(); // `id` for subcategory, `mid` for business material id
    const { data, isLoading, error, refetch } = useGetBusinessmaterialtypeQuery({ id, mid }); // Pass both ids
    const [showMatrialType, setShowMaterialType] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
      };
      const handleShowMaterialType = () =>{
        setShowMaterialType(true)
      }
const handleCloseMaterialTypeModal =  () =>{
    setShowMaterialType(false)
  }
    return (
        <div>
            <Link onClick={handleBackClick}>
                <img src={BackIcon} alt="BackIcon" className="back-icon" />
            </Link>
            <h3>Business development materials</h3>
            <div className="table-wrapper-div">
                {/* <div className="sub-head-table">
                    <h4>{data?.subcategory}</h4>
                </div> */}
                 <div className="sub-haed-table">
                <div className="d-flex justify-content-space-between">
                <h4>{data?.subcategory}</h4>
                    <button className="primary-btn" onClick={handleShowMaterialType}>Add </button>
                    </div>
                </div>
                {isLoading && <p>Loading ...</p>}
                {error && <p>Failed to load. Please try again later.</p>}
                {!isLoading && !error && data && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Material Type</th>
                                <th scope="col">No of documents</th>
                                <th scope="col">No of saves</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.material_types.map((materialType) => (
                                <tr key={materialType.id}>
                                    <td className="clr-bold">{materialType.name}</td>
                                    <td>{materialType.uploaded_records_count}</td>
                                    <td>{materialType.saved_records_count}</td>
                                    <td>
                                        {/* Update the link to pass both materialType id and subcategory id */}
                                        <Link to={`/workbook/${data.business_material_id}/${data.subcategory_id}/${materialType.id}`}>
                                            <button className="btn-outline">View</button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <AddMaterialtypeMpdal show={showMatrialType} handleCloseMaterialTypeModal={handleCloseMaterialTypeModal} refetch={refetch}/>
        </div>
    );
}

export default BusinessDevelopmentMaterialtype;
