import React, { useState ,useEffect} from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useGetAlllistingswithstatetsQuery,useSuspendUserMutation, useGetflagshiplistingswithuserdetailstsQuery, useGetTsHeadquarterDataQuery } from "../Services/UsersApi";
import OrImg from "../Assets/Images/or-img.svg";
import Logo from "../Assets/Images/default-logo.svg";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useParams } from "react-router-dom";

function UserManagement() {
  const { id } = useParams();
 
  const { data: fldata } = useGetTsHeadquarterDataQuery({ id });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [activeButton, setActiveButton] = useState("All");
  const [isActive, setIsActive] = useState(true);
  const [submitForm, { refetch: refetchSuspendUser }] = useSuspendUserMutation();

  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { data, isLoading, error, refetch } = useGetAlllistingswithstatetsQuery({ id , selectedStatus});

  useEffect(() => {
    if (fldata) {
      console.log("fldata", fldata?.user?.is_active);
      setIsActive(fldata?.user?.is_active);
    }
  }, [fldata])

  useEffect(()=>{
    refetch();
      }, [])

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading data...</p>;
  }

  // Flatten the data structure to get all branches in one array
  const branches = data?.reduce((acc, stateData) => {
    return acc.concat(stateData?.branches?.map(branch => ({
      ...branch,
      state: stateData.state
    })));
  }, []) || [];

  // Filter users based on search input
  const filteredBranches = branches?.filter((branch) => {
    const businessName = branch?.business_name?.toLowerCase();
    const email = branch.email ? branch.email.toLowerCase() : "";
    const searchLower = searchTerm.toLowerCase();

    return businessName?.includes(searchLower) || email?.includes(searchLower);
  });

  // Function to export users to CSV
  const exportToCSV = () => {
    const csvHeaders = [
      "Business Name",
      "Email",
      "Contact Number",
      "State",
      "Created At",
      "Subscription Type",
      "Flagship"
    ];

    const csvRows = filteredBranches?.map((branch) => [
      branch.business_name,
      branch.email || "N/A",
      branch.contact_number || "N/A",
      branch.state,
      new Date(branch.created_at).toLocaleDateString(),
      branch.subscription_type,
      branch.flagship ? "Yes" : "No",
    ].join(","));

    const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "branches_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleSuspendUser = async () => {
    const data = { is_active: !isActive };
    try {
      const response = await submitForm({ data, id });
      if (response.error) {
        alert("Submission failed!");
      } else {
        console.log("response", response)
        alert("Your account status has been changed");
        window.location.reload();
        setIsActive(!isActive);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleresetStatus = () => {
    setSelectedStatus("");
    setIsDropdownOpen(false);
    refetch();
  };
  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false);
    console.log("Selected status:", status);
    refetch();
  };

  return (
    <div>
      <div className="back-page-icon">
        <img src={BackIcon} alt="" onClick={handleBackClick} />
      </div>
      <div className="table-wrapper-div view-user-detail">
        <div className="table-head-with-filter">
          <Row>
            <Col xs={12} md={3} lg={3} sm={12}>
              <h5>
                {/* <img src={OrImg} alt="OrImg" /> */}
                {fldata?.profile?.first_name}
              </h5>
              <p className="email">{fldata?.user?.email}</p>
              <p className="phone-no">{fldata?.user?.phone_number}</p>
              <div className="flagship-div">
                <h4>Flagship</h4>
                {fldata?.flagship_branch?.length > 0 ? (
                  <p>{fldata?.flagship_branch[0]?.location}</p>
                ) : (
                  <p>No flagship branch available</p>
                )}
              </div>
            </Col>

            <Col xs={12} md={9} lg={9} sm={12}>
            <div className="text-end account-status">
                 <button
                  onClick={handleSuspendUser}
                  className={isActive ? 'suspend-button' : 'reactivate-button'}
                >
                  {isActive ? 'Suspend' : 'Reactivate'}
                </button>
              </div>
              <div className="search-div">
                <div className="form-control-input">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <img src={SearchImg} alt="Search" className="search-img" />
                </div>
                
             <div className="filter-main-div">
                  <button className="filter-btn" onClick={toggleDropdown}>
                    <img src={FilterImg} alt="Filter" /> Filters
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu-filter">
                      <p className="reset-filter text-end" onClick={handleresetStatus}>Reset</p>

                      <div onClick={() => handleStatusClick("pro")} className="dropdown-item-status">Pro</div>


                      <div onClick={() => handleStatusClick("unlisted")} className="dropdown-item-status">Unlisted</div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Business Name</th>
                <th scope="col">Operational status</th>
                <th scope="col">Contact info</th>
                <th>Added on</th>
                <th>Last updated</th>
                <th>Status</th>
                <th>Sub plan</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredBranches?.map((branch) => (
                <tr key={branch.id}>
                  <td className="clr-bold">
                    <Link to={`/user-trade-location-details/${branch.id}`}>
                      <div className="d-flex">
                        <span>
                          <img src={Logo} alt="Logo" />
                        </span>
                        <div className="marl-20">
                          {branch.business_name}
                          <br />
                          <span className="address-user-td">
                            {branch.address || "Address not available"}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td>
                    {branch?.operational_status?.map((status) => status.name)
                      .join(", ") || "N/A"}
                  </td>
                  <td>
                    {branch.email || "Email not available"} <br />
                    {branch.contact_number || "Contact not available"}
                  </td>
                  <td>{new Date(branch.created_at).toLocaleDateString()}</td>
                  <td>{new Date(branch.updated_at).toLocaleDateString()}</td>
                  <td className="d-flex">
                  <button
                      className={`status-btn ${branch?.is_active ? "approved-status" : "pending-status"
                        }`}
                    >
                      <p
                        className={
                          branch?.is_active ? "green-circle" : "red-circle"
                        }
                      ></p>
                      <span>
                        {branch?.is_active ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </td>
                  <td>
                    <span className="pro-text">
                      {branch.subscription_type.toUpperCase()}
                    </span>
                  </td>
                  <td className="text-end">
                    <Link to={`/user-trade-location-details/${branch.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="prev-next-btn text-end">
            <button type="button">Previous</button>
            <button type="button">Next</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
